<template>
	<div class="ng-content-wrap">
		<div class="ng-block-head">
			<div class="ng-block-head-sub d-print-none">
				<a class="back-to" href="/tickets">
					<em class="icon ni ni-arrow-left-c mr-1"></em>
					<span>My Tickets</span>
				</a>
			</div>
			<div class="ng-block-between-md g-4 align-items-end">
				<div class="ng-block-head-content">
					<h2 class="ng-block-title fw-normal">
						{{ ticket.subject }}
					</h2>
				</div>
				<div class="ng-block-head-content">
					<ul class="ng-block-tools g-4 flex-wrap"></ul>
				</div>
			</div>
		</div>
		<div class="ng-block">
			<div class="ticket-info">
				<ul class="ticket-meta">
					<li class="ticket-id">
						<span>Ticket ID:</span>
						<strong>{{ ticket.id }}</strong>
					</li>
					<li class="ticket-date">
						<span>Submitted:</span>
						<strong>{{ fD(ticket.created_at) }}</strong>
					</li>
				</ul>
				<div class="ticket-status">
					<span class="badge badge-success">Open</span>
				</div>
			</div>
		</div>
		<div class="ng-block ng-block-lg">
			<div class="card card-bordered">
				<div class="card-inner">
					<div class="ticket-msgs">
						<div class="ticket-msg-item">
							<div class="ticket-msg-from">
								<div class="ticket-msg-user user-card">
									<div class="user-avatar bg-primary">
										<img
											:src="ticket.user?.avatar"
											:alt="ticket.user?.name"
										/>
									</div>
									<div class="user-info">
										<span class="lead-text"
											>
											{{ ticket.user?.name }}
											</span
										>
										<span class="text-soft">{{ ticket.user?.role }}</span>
									</div>
								</div>
								<div class="ticket-msg-date">
									<span class="sub-text"
										>
										{{ fD(ticket.updated_at) }}
										</span
									>
								</div>
							</div>
							<div class="ticket-msg-comment" v-html="ticket.message"></div>
						</div>
						<div class="ticket-msg-item is-mine d-none">
							<div class="ticket-msg-from">
								<div class="ticket-msg-user user-card">
									<div class="user-avatar bg-warning">
										<span>AB</span>
									</div>
									<div class="user-info">
										<span class="lead-text"
											>Abu Bin Ishtiyak</span
										><span class="text-soft"
											>Support Team</span
										>
									</div>
								</div>
								<div class="ticket-msg-date">
									<span class="sub-text">Just Now</span>
								</div>
							</div>
							<div class="ticket-msg-comment">
								<p>
									<strong>Hello Kenneth,</strong>
								</p>
								<p>
									Thanks for contact us with your issues.
									Excepteur sint occaecat cupidatat non
									proident, sunt in culpa qui officia deserunt
									mollit anim id est laborum.
								</p>
								<p>
									Sed ut perspiciatis unde omnis iste natus
									error sit voluptatem accusantium doloremque
									laudantium, totam rem aperiam, eaque ipsa
									quae ab illo inventore veritatis et quasi
									architecto beatae vitae dicta sunt
									explicabo.
								</p>
							</div>
							<div class="ticket-msg-attach">
								<h6 class="title">
									Attachment
								</h6>
								<ul class="ticket-msg-attach-list">
									<li>
										<a href="#"
											><img
												src="/images/gfx/attach-a.jpg"
												alt=""/><em
												class="icon ni ni-download"
											></em
										></a>
									</li>
									<li>
										<a href="#"
											><img
												src="/images/gfx/attach-b.jpg"
												alt=""/><em
												class="icon ni ni-download"
											></em
										></a>
									</li>
								</ul>
							</div>
						</div>
						<div class="ticket-msg-reply">
							<h5 class="title">
								Reply
							</h5>
							<form action="#" class="form-reply">
								<div class="form-group">
									<div class="form-editor-custom">
										<textarea
											class="form-control"
											placeholder="Write a message..."
										></textarea>
										<div class="form-editor-action">
											<ul class="form-editor-btn-group">
												<li class="form-btn-more">
													<a
														href="#"
														data-toggle="tooltip"
														title="Add File"
														class="btn btn-icon"
														><em
															class="icon ni ni-clip-h"
														></em
													></a>
												</li>
												<li class="form-btn-more">
													<a
														href="#"
														data-toggle="tooltip"
														title="Add Image"
														class="btn btn-icon"
														><em
															class="icon ni ni-img"
														></em
													></a>
												</li>
												<li class="form-btn-more">
													<a
														href="#"
														data-toggle="tooltip"
														title="Add Link"
														class="btn btn-icon"
														><em
															class="icon ni ni-link"
														></em
													></a>
												</li>
											</ul>
										</div>
									</div>
								</div>
								<div class="form-action">
									<ul class="form-btn-group">
										<li class="form-btn-primary">
											<a href="#" class="btn btn-primary"
												>Send</a
											>
										</li>
										<li class="form-btn-secondary">
											<a
												href="#"
												class="btn btn-dim btn-outline-light"
												>Mark as close</a
											>
										</li>
									</ul>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import { defineComponent, ref, onMounted, toRefs } from "vue";
	import http from "../services/http";
	import store from "../services/store";
	import { format } from "date-fns"

	export default defineComponent({
		props: {
			id: String
		},
		setup(props) {
			const ticket = ref({});
			const { user } = toRefs(store.state);

			function fetchTicket() {
				http.get(`/api/tickets/${props.id}`).then(
					(res: any) => {
						ticket.value = res.data;
					}
				);
			}

			onMounted(() => {
				fetchTicket();
			});

			function fD(d: any, f = "PPPP") {
				return format(new Date(d), f);
			}

			return { ticket, user, fD };
		},
	});
</script>

