
	import { defineComponent, ref, onMounted, toRefs } from "vue";
	import http from "../services/http";
	import store from "../services/store";
	import { format } from "date-fns"

	export default defineComponent({
		props: {
			id: String
		},
		setup(props) {
			const ticket = ref({});
			const { user } = toRefs(store.state);

			function fetchTicket() {
				http.get(`/api/tickets/${props.id}`).then(
					(res: any) => {
						ticket.value = res.data;
					}
				);
			}

			onMounted(() => {
				fetchTicket();
			});

			function fD(d: any, f = "PPPP") {
				return format(new Date(d), f);
			}

			return { ticket, user, fD };
		},
	});
